import React, { useState, useMemo, Fragment, useEffect } from 'react'
import s from "./../styles/index.module.scss";
import cn from 'classnames'
import Logo from './../icons/logo.svg'
import FooterLogo from './../icons/footer-logo.svg'
import TypeSvg1 from './../icons/footer/type1.svg'
import TypeSvg2 from './../icons/footer/type2.svg'
import ErtificationSvg1 from './../icons/footer/2013.svg'
import ErtificationSvg2 from './../icons/footer/2015.svg'
import ErtificationSvg3 from './../icons/footer/2019.svg'
import XSvg from './../icons/footer/X.svg'
import EmailSvg from './../icons/footer/email.svg'
import FaceSvg from './../icons/footer/facebook.svg'
import InSvg from './../icons/footer/in.svg'
import TelSvg from './../icons/footer/tel.svg'
import Image from 'next/image'




const Home = () => {
    const [currentYear, setCurrentYear] = useState(2024)
    useEffect(() => {
        setCurrentYear(new Date().getFullYear())

    }, [])

    const benefitsList = useMemo(
        () => ({
            title: 'Key benefits for OTC/Investment Brokerage Platforms adopting digital asset solutions',
            list: [
                {
                    id: '0',
                    title: 'Attracting New Clients and Diversifying Offerings',
                    items: [
                        {
                            id: '1',
                            title: 'Capture the growing crypto market',
                            desc: 'Attract tech-savvy investors seeking exposure to digital assets and stay ahead of the curve.'
                        },
                        {
                            id: '2',
                            title: 'Expand portfolio diversification',
                            desc: 'Offer a wider range of asset classes, catering to clients seeking alternative investment options.'
                        },
                        { id: '3', title: 'Enhance brand image', desc: 'Demonstrate innovation and leadership in the evolving financial landscape.' }
                    ],
                    imgUrl: '/images/benefits1.svg'
                },
                {
                    id: '2',
                    title: 'Boosting Revenue and Profitability',
                    items: [
                        {
                            id: '1',
                            title: 'Generate new revenue streams',
                            desc: 'Earn income from trading fees, custody services, staking rewards, and other crypto-related offerings.'
                        },
                        {
                            id: '2',
                            title: 'Increase client engagement and transaction volume',
                            desc: 'Encourage increased trading activity and asset allocation within the platform.'
                        },
                        { id: '3', title: 'Optimize operational efficiency', desc: 'Reduce costs through blockchain-based automation and streamlined processes.' }
                    ],
                    imgUrl: '/images/benefits2.svg'

                },
                {
                    id: '3',
                    title: 'Building Trust and Enhancing Security',
                    items: [
                        {
                            id: '1',
                            title: "Leverage blockchain's security and transparency",
                            desc: 'Build trust with clients by offering secure storage and transparent transaction records.'
                        },
                        {
                            id: '2',
                            title: 'Improve compliance and risk management',
                            desc: 'Facilitate automated KYC/KYT/AML checks and enhance regulatory compliance.'
                        },
                        { id: '3', title: 'Mitigate operational risks', desc: 'Reduce reliance on intermediaries and increase resilience against fraud and cyberattacks.' }
                    ],
                    imgUrl: '/images/benefits3.svg'
                },
                {
                    id: '4',
                    title: 'Positioning for the Future of Finance',
                    items: [
                        {
                            id: '1',
                            title: 'Prepare for a blockchain-powered future',
                            desc: 'Gain a competitive edge by adapting to the evolving technological landscape.'
                        },
                        {
                            id: '2',
                            title: 'Drive innovation and develop new financial products',
                            desc: 'Leverage the potential of DeFi and other emerging crypto applications.'
                        },
                        { id: '3', title: 'Become a leader in the digital asset ecosystem', desc: 'Partner with blockchain startups and contribute to shaping the future of finance.' }
                    ],
                    imgUrl: '/images/benefits4.svg'
                }
            ] as any[]
        }),
        []
    )

    const page4 = useMemo(
        () => ({
            title: 'Practical uses for OTC',
            cardList: [
                {
                    title: 'Digital Asset Exchanges',
                    content: 'Expand investment options through trading a wider range of digital assets, beyond just cryptocurrencies, offer stablecoins, security tokens, DeFi products, and more.',
                    img: ['/images/page4/exchanges.svg'],
                    theme: 'green'

                },
                {
                    title: 'Digital asset custody solutions',
                    content: "Securely store and manage clients' digital assets, including digital assets, tokens, and NFTs, with robust security measures and regulatory compliance.",
                    img: ['/images/page4/custody-solutions.svg'],
                    theme: 'blue'
                },
                {
                    title: 'Compliance and Risk Management',
                    content: 'Utilize blockchain-based KYC/KYT/AML solutions to streamline customer onboarding, identity verification, and transaction monitoring, mitigating financial crime risks.',
                    img: ['/images/page4/compliance-risk.svg'],
                    theme: 'purple'

                }
            ]
        }),
        []
    )


    const page5 = useMemo(
        () => ({
            wrapperBg: '#fff',
            title: "ChainUp (Canada)'s Solutions for OTC",
            list: [
                {
                    id: '1',
                    title: 'Digital Asset Exchange Platform Development',
                    desc: 'Launch best-in-class, customized and secure digital asset exchanges, unlocking new revenue streams.',
                    img: '/images/exchange.webp',
                    title1: 'Know-Your-Transaction (KYT) Risk Control Technology',
                    desc1: "Streamline onboarding, boost compliance, and safeguard client assets with ChainUp's AI-powered KYT risk control tech for efficient and frictionless banking in the digital age.",
                },
                {
                    id: '2',
                    title: 'Institutional-grade Wallet-as-a-service ',
                    desc:
                        "Guarantee the safety of your clients' digital assets with robust security measures and cold storage solutions with ChainUp's seamless, scalable, and compliant Wallet-as-a-Service."
                    ,
                    img: '/images/mpc.webp',
                    title1: 'Liqudity-as-a-service ',
                    desc1: 'One-stop market-making liquidity solution for fiat, spot, and derivative markets.',

                }
            ]
        }),
        []
    )
    return (
        <>
            <div className={s.page1}>

                <div className={s.logo_wrapper}>
                    <Logo className={s.logo}></Logo>
                </div>
                <div className={s.banner}>
                    <div className={s.banner_left}>
                        <div className={s.sub_title}>ChainUp (Canada) for OTC</div>
                        <h1 className={s.title}>Forex to Stocks to Digital Assets, One Seamless Flow.Trade it all, Grow with It.</h1>
                    </div>
                    <div className={s.banner_right}>
                        <Image src={'/images/bannerImg.svg'} layout="fill" alt=""></Image>
                    </div>

                </div>
            </div >

            <div className={s.page2}>
                <h1 className={s.page2_title}>As the digital asset tide rises, investment platforms face a pivotal choice: embrace the wave or get swept away.</h1>
                <p className={s.page2_desc}>Integrating digital assets unlocks a treasure trove of possibilities: attracting tech-savvy investors, diversifying portfolios with cutting-edge assets, and generating new revenue streams through trading fees and custody services. Blockchain's inherent security and transparency foster trust, enhance compliance, and streamline operations. This isn't just a new asset class, it's a paradigm shift, transforming platforms into one-stop shops for the digital age's investors.</p>
            </div>
            <div className={s.page3} >
                <h1 className={cn(s.page3_title)}>
                    {benefitsList.title}
                </h1>
                <div className={cn(s.page3_list)}>
                    {benefitsList.list.map((item) => (
                        <div className={cn(s.model)} key={item.id}>
                            <div className={cn(s.content)}>
                                <p className={cn(s.contentTitle)}>
                                    {item.title}
                                </p>
                                {item.items.map((descItem: any) => (
                                    <Fragment key={descItem.id}>
                                        <p className={cn(s.contentKey)}>
                                            {descItem.title}
                                        </p>
                                        <p className={cn(s.contentVal)}>
                                            {descItem.desc}
                                        </p>
                                    </Fragment>
                                ))}
                            </div>
                            <div className={cn(s.img)}>
                                <Image layout="fill" src={item.imgUrl} alt="" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={s.practical} >
                <h1 className={cn(s.practical_title)}>
                    {page4.title}
                </h1>
                <div className={cn(s.gap)} style={{ gridTemplateColumns: `repeat(3, 1fr)`, marginBottom: '0px' }}>
                    {
                        page4.cardList.map((item, index) => {
                            return (
                                <div className={cn(s.content, 'practical-wrapper')} key={`page_1_${index}`} >
                                    <div className={cn(s.model, s[`${item.theme}`])}>
                                        <p className={cn(s.modelKey)}>
                                            {item.title}
                                        </p>
                                        <p className={cn(s.modelVal)} >
                                            {item.content}
                                        </p>

                                    </div>
                                    <div className={cn(s.rectangle, 'rectangle')}>
                                        <div className={cn(s.img)}>
                                            {item.img.map((src: string | undefined, i: any) => {
                                                return <img key={`page_1_img_${i}`} src={src} alt="" />
                                            })}
                                        </div>

                                    </div>
                                </div>
                            )
                        })}


                </div>

            </div>
            <div className={cn(s.page5)} style={{ background: page5.wrapperBg || '#f2f8ff' }}>
                <p className={cn(s.page5_title)}  >
                    {page5.title}
                </p>
                {page5.list.map((item) =>
                    <div key={item.id} className={cn(s.page5_list)} >
                        <div
                            className={cn(s.page5_item, s.page5_item_left)}
                        >
                            <div className={s.content_left}>
                                <h2 className={cn(s.content_title)} dangerouslySetInnerHTML={{ __html: item.title }} />
                                <p className={cn(s.content_desc)}  >
                                    {item.desc}
                                </p>
                            </div>
                            <div className={s.content_right}  >
                                <Image layout="fill" src={item.img} alt=""></Image>
                            </div>

                        </div>

                        <div
                            className={cn(s.page5_item, s.page5_item_right)}
                            onClick={() => {
                            }}
                        >
                            <div>
                                <h2 className={cn(s.content_title)} dangerouslySetInnerHTML={{ __html: item.title1 }} />
                                <p className={cn(s.content_desc, 'text3')}  >
                                    {item.desc1}
                                </p>
                            </div>

                        </div>

                    </div>

                )}
            </div>
            <div className={s.footer}>
                <div className={s.footer_content}>
                    <FooterLogo className={s.footer_logo}></FooterLogo>
                    <div className={s.certification_text}>Our Certification</div>

                    <div className={s.certification_wrapper}>
                        <div className={s.certification_logo_wrapper}>
                            <div>
                                <TypeSvg1 className={s.certification_logo}></TypeSvg1>
                                <TypeSvg2 className={s.certification_logo}></TypeSvg2>
                            </div>
                            <div>
                                <ErtificationSvg2 className={s.certification_logo}></ErtificationSvg2>
                                <ErtificationSvg1 className={s.certification_logo}></ErtificationSvg1>
                                <ErtificationSvg3 className={s.certification_logo}></ErtificationSvg3>
                            </div>

                        </div>
                        <div>
                            <EmailSvg className={s.footer_media}></EmailSvg>
                            <InSvg className={s.footer_media}></InSvg>
                            <FaceSvg className={s.footer_media}></FaceSvg>
                            <XSvg className={s.footer_media}></XSvg>
                            <TelSvg className={s.footer_media}></TelSvg>
                        </div>
                    </div>
                    <div className={s.copyRight}>
                        {`Copyright © ${currentYear} ChainUp Canada`}
                    </div>

                </div>
            </div>

        </>

    );
}




export default Home